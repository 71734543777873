.sectionPropos {
    display: flex;

    .containerPropos {
        margin: 4.5% 10%;
        display: flex;
        flex: 1;

        @media (max-width: 769px) {
            margin: 5% 2%;
            flex-direction: column;
        }
        
        .textPropos {
            display: flex;
            flex-direction: column;
            flex: 1.1;
            margin: 20px;

            article {
                max-height: 155px;
                overflow: auto;
                scrollbar-width: none;

                @media (max-width: 426px) {
                    max-height: unset;
                }

                &::-webkit-scrollbar {
                    width: 0;  /* Remove scrollbar space */
                    background: transparent;
                }
            }

            p, article {
                margin-bottom: 0;
            }

            p {
                margin-top: 0;
            }
        }

        h1 {
            font-weight: 800;
            font-size: 70px;
            line-height: 58px;
            text-transform: uppercase;
            margin-bottom: 15px;

            @media (max-width: 1200px) {
                font-size: 40px;
                line-height: 38px;
            }

            @media (max-width: 769px) {
                margin: 0 0;
                font-size: 50px;
                line-height: 48px;
            }
    
            @media (max-width: 521px) {
                font-size: 38px;
                line-height: 36px;
            }
    
            @media (max-width: 376px) {
                font-size: 30px;
                line-height: 28px;
            }
        }

        .containerIconPropos {
            display: flex;
            flex: 0.9;
            justify-content: space-around;
            align-items: center;
            margin: 10px 20px;

            @media (max-width: 769px) {
                margin-top: 30px;
                margin-bottom: 30px;
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    height: 50px;
                    display: flex;
                    margin-bottom: 10px;
                }

                span {
                    font-weight: 700;
                    text-transform: uppercase;
                }
                p {
                    margin: 0;
                }
            }
        }

        .noImg {
            @media (max-width: 769px) {
                margin: 0!important;
            }
        }
    }
}
