.lastTweet {
    font-family: 'Poppins', sans-serif;
    padding-bottom: 10px;

    .title {
        display: flex;
        color: #000;
        font-size: 70px;
        font-weight: 800;
        line-height: 58px;
        text-transform: uppercase;
        margin: 0;
        padding: 50px 10% 10px 10%;
        justify-content: center;
        align-items: center;

        @media (max-width: 1200px) {
            font-size: 40px;
            line-height: 48px;
        }

        @media (max-width: 769px) {
            margin: 30px;
            font-size: 50px;
            line-height: 48px;
        }

        @media (max-width: 521px) {
            font-size: 38px;
            line-height: 36px;
        }

        @media (max-width: 376px) {
            font-size: 30px;
            line-height: 28px;
        }

        img {
            margin-left: 20px;
            max-width: 40px;
        }
    }

    .listTweet {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 20px 10%;

        &:after {
            content: "";
            flex: 0 0 50%;
        }

        .containerOneTweet {
            border: 1px solid #a6a6a6;
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin: 10px;
            border-radius: 4px;
            flex: 0 0 20%;

            @media (max-width: 479px) {
                flex: 0 0 88%;
            }

            a {
                text-decoration: none;
                color: #000;
    

                .containerHeaderTweet {
                    display: flex;
                    font-size: 10px;

                    img {
                        width: 38px;
                        height: 38px;
                        border-radius: 100%;
                    }

                    div {
                        
                        p {
                            margin: 0 5px;
                        }

                        .greyInfo {
                            color: #a6a6a6
                        }
                    }
                }

                .containerBodyTweet {
                    display: flex;
                    flex-direction: column;
                    font-size: 10px;

                    .contentContainer {
                        font-size: 10px;
                    }

                    .containerImg {
                        display: flex;
                        flex-wrap: wrap;

                        img {
                            width: 120px;
                            flex: 0 0 5%;
                        }
                    }
                    
                    .containerQuoteTweet {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
