.oneNews {
    display: flex;
    flex-direction: column;
    padding-top: 120px;

    div.containerTitle {
        display: flex;
        margin: 0 10% 20px 10%;
        flex-direction: column;

        @media (max-width: 920px) {
            margin: 10px 30px;
        }

        .actionContainer {
            display: flex;
            align-items: center;
            
            a {
                text-decoration: none;
                width: 70px;
                max-height: 100px;
                align-self: flex-start;
    
                .chevronLeft {
                    margin: 1% 10px 0 0;
                    background-image: url("/images/return.png");
                    width: 100%;
                    height: 70px;
                    background-size: cover;
                    background-repeat: no-repeat;

                    @media (max-width: 920px) {
                        width: 50%;
                        height: 50px;
                    }
                }
            }
            h1 {
                flex: 3;
                font-size: 60px;
                margin-right: 10px;
                line-height: 67px;
                
                &::first-letter {
                    text-transform: uppercase;
                }
    
                @media (max-width: 920px) {
                    font-size:  35px;
                    line-height: 35px;
                }
    
                @media (max-width: 768px) {
                    font-size:  25px;
                    line-height: 35px;
                }
            }
        }

        .infoContainer {
            
            p {
                font-size: 18px;
                align-self: flex-end;
                margin-left: 10px;
                color:  #B1B1B1;
            }
        }
    }

    .blockNews {
        margin: 0 10% 40px 10%;
        display: flex;

        @media (max-width: 768px) {
            margin: 10px 30px;
        }

        @media (max-width: 520px) {
            margin: 10px;
        }
        
        img {
            max-width: 395px;
            align-self: flex-start;
            margin-bottom: 30px;

            @media (max-width: 920px) {
                max-width: 310px;
                align-self: center;
                margin-bottom: 10px;
            }
            
        }

        .article {
            margin-left: 20px;
            width: 100%;

            @media (max-width: 769px) {
                margin-left: 0;
            }

            article.newsText {
                margin: 0 40px;
                font-size: 16px;

                @media (max-width: 769px) {
                    margin: 0 10px;
                }
            }

            a, .subGame {
                text-decoration: none;
                display: block;
                margin: 20px auto;

                .btnSkew {
                    width: 230px;
                    margin: 0 auto;

                    div {
                        height: 40px;
                    }
                }
            }

            article p a {
                display: inline;
            }

            .containerForm {
                display: flex;
                flex-direction: column;
                flex: 1;
    
                form {
                    display: block;
                    margin: 20px 40px 20px 0 ;
    
                    @media (max-width: 520px) {
                        margin: 0 5%;
                    }
    
                    label {
                        display: flex;
                        width: 35%;
                        float: left;
                        justify-content: flex-end;
                        margin-bottom: 20.5px;
                        height: 40px;
                        align-items: center;
                        text-align: right;
    
                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
    
                    input, textarea {
                        padding: 0;
                        display: flex;
                        width: 61%;
                        float: right;
                        resize: vertical;
    
                        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: #cecece;
                            opacity: 1; /* Firefox */
                        }
                        
                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: #cecece;
                        }
                        
                        &::-ms-input-placeholder { /* Microsoft Edge */
                            color: #cecece;
                        }
    
                        @media (max-width: 768px) {
                            width: 95%;
                            margin: 20px 0;
                        }
                    }
    
                    input {
                        border: none;
                        border-bottom: 1px solid #cecece;
                        margin-bottom: 20px;
                        height: 40px;
                    }
    
                    textarea {
                        border: 1px solid #cecece;
                        margin-bottom: 20px;
                        padding: 5px;
                        width: 59%;
    
                        @media (max-width: 768px) {
                            width: 95%;
                            margin: 20px 0;
                        }
                    }
                }
    
    
                .btnSkew {
                    display: flex;
                    width: 45%;
                    margin: 20px 20px 20px 39%;
    
                    @media (max-width: 768px) {
                        width: 60%;
                        margin: 20px auto;
                    }
    
                    div {
                        flex: 1;
                        height: 40px;
                    }
                }
    
                .btnSend {
                    visibility: hidden;
                }
    
                .alert-success {
                    color: #90C97E;
                    transition: .3s;
                }
            }

            .jwplayer, .fp-hlsjs {
                margin: 0!important;
                width: 100%!important;
                max-height: 310px!important;
                height: 310px!important;
            }


        }


        @media (max-width: 920px) {
            flex-direction: column;
        }

        .video {
            display: flex;
            justify-content: center;

            iframe {
                min-width: 44vw !important;
            }
        }

        .success-register {
            color:#90C97E;
            text-align: center;
        }

        .error-register {
            color:#da2f2f;
            text-align: center;
        }

    }
}
