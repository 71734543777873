.containerModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);

    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;
    }

    .modal {
        flex-direction: column;
        text-align: center;
        margin: 100px auto;
        background: #fff;
        border-radius: 4px;
        max-width: 780px;
        padding: 25px;

        @media (max-width: 769px) {
            max-width: 90%;
            margin: 100px 10px;
        }

        .logo img{
            margin: 50px auto 10px auto;
        }

        h3 {
            font-size: 25px;
            width: 70%;
            margin: 10px auto 20px auto;
            font-weight: 700;

            @media (max-width: 769px) {
                margin: 0 auto;
            }
        }

        p {
            margin: 10px 20px;
            max-height: 230px;
            overflow: hidden;
            overflow-y: auto;

            @media (max-width: 769px) {
                margin: 0;
            }
        }

        input {
            width: 70%;
            height: 40px;
            padding: 5px 20px;
            font-size: 18px;
            border: 1px solid #d6d6d6;
            color: #000;
            border-radius: 4px;
        }

        .btnSkew {
            width: 40%;
            margin: 20px auto 5px auto;
        }

        a.knowMore {
            display: inline-block;
            font-weight: 500;
            margin: 10px auto 50px auto;
            font-size: 16px;
            color: #000;
            text-decoration: none;
        }
    }
}

#modalVideo {
    display: none;
}

#newsletters {
    display: none;
}

#rgpdModal {
    display: none;

    .modal {
        display: flex;

        h3 {
            margin-top: 60px;

            @media (max-width: 769px) {
                margin-top: 0;
            }
        }

        .containerBtn {
            margin: 20px 0 50px 0;
            display: flex;
            justify-content: center;

            @media (max-width: 769px) {
                margin: 20px 0 10px 0;
            }

            .btnSkew {
                margin: 0 10px;

                div span {
                    padding: 5px 30%;

                    @media (max-width: 565px) {
                        padding: 5px 0;
                    }
                }
            }

            .denied {
                //background: #d6d6d6;

                div {
                    span {
                        //color: #d6d6d6;
                    }
                    &:hover {
                        background: none;

                        span {
                            color: #fff;
                        }
                    }
                } 
            }
        }
    }
    
    .rgdpUseLink {
        color: #000;
        margin-bottom: 60px;
    }
}

#concours {
    display: none;
    
    .concoursForm {
        margin: 10px 20px;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;

        @media (max-width: 768px) {
            margin: 0;
        }

        form {
            display: block;
            margin: 20px 0 20px 0;
            padding-right: 20px;

            @media (max-width: 520px) {
                margin: 0 5%;
            }

            div {
                display: flex;
                justify-content: space-between;

                label {
                    display: flex;
                    width: 20%;
                    float: left;
                    justify-content: flex-end;
                    margin-bottom: 20.5px;
                    height: 40px;
                    align-items: center;
                    text-align: right;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                input, textarea {
                    padding: 0;
                    display: flex;
                    width: 75%;
                    float: right;
                    resize: vertical;
                    border-radius: 0;

                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #cecece;
                        opacity: 1; /* Firefox */
                    }
                    
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #cecece;
                    }
                    
                    &::-ms-input-placeholder { /* Microsoft Edge */
                        color: #cecece;
                    }

                    @media (max-width: 768px) {
                        width: 95%;
                        margin: 20px 10px;
                    }
                }

                input {
                    border: none;
                    border-bottom: 1px solid #cecece;
                    margin-bottom: 20px;
                    height: 40px;
                }

                textarea {
                    border: 1px solid #cecece;
                    margin-bottom: 20px;
                    padding: 5px;
                    width: 59%;

                    @media (max-width: 768px) {
                        width: 95%;
                        margin: 20px 10px;
                    }
                }

            }

            .checkboxContainer {
                justify-content: space-between;
            
                div {
                    width: 75%;
                    display: flex;
                    align-items: flex-start;

                    @media (max-width: 768px) {
                        width: 95%;
                        margin: 20px 10px;
                    }

                    input[type="checkbox"] {
                        height: 20px;
                        width: auto;
                        margin: 10px 0 0 0;
                    }
                    p {
                        text-align: left;
                        margin: 5px 10px;
                    }
                }

                .mentionsLegal {
                    max-height: 310px;
                    text-align: justify;
                    padding-right: 20px;
                    margin-right: 0;
                }

            }
        }


        .btnSkew {
            display: flex;
            width: 45%;
            margin: 20px 20px 20px 39%;

            @media (max-width: 768px) {
                width: 60%;
                margin: 20px auto;
            }

            div {
                flex: 1;
                height: 40px;
                display: flex;
                justify-content: center;
            }
        }

        .btnSend {
            visibility: hidden;
        }

        .alert-success {
            color: #90C97E;
            transition: .3s;
        }
    }
}
