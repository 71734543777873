
//ALL NEWS
.listActualite {
    display: flex;
    flex-direction: column;
    padding: 40px 5% 40px 5%;

    h1.title {
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 70px;

        @media (max-width: 520px) {
            font-size: 38px;
        }
    }

    .containerList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .columnActu {
            //flex: 0 0 25%;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            margin: 20px 0;

            @media (max-width: 1236px) {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
            }
            @media (max-width: 992px) {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (max-width: 768px) {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (max-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
            }

            a {
            text-decoration: none;
        
                .actualite {
                    flex-direction: column;
                    margin: 10px auto;
                    max-width: 260px;
                    display: flex;
                    text-decoration: none;
                    border-radius: 4px;
                    transition: .3s;
                    align-items: center;

                    .createdAt, .title, .contents, .knowMore{
                        align-self:flex-start;
                        padding: 5px;
                        margin: 0 10px;
                        color: black;
                    }
                    .createdAt {
                        color: #B1B1B1;
                        text-align: left;
                    }
                    

                    .contents {
                        margin: 0 10px;
                        padding: 0 5px;

                        .newsText {
                            margin-bottom: 10px;
                      
                            p {
                                margin: 0;
                            }
                        }
                    }
                    .knowMore {
                        text-decoration: underline;
                        margin-top: 10px;
                    }
                    
                    .cover {
                        width: 100%;
                        height: 380px;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        background-position: center !important;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }

                    .infoActu {
                        //padding: 10px;
                        max-width: 280px;
                        width: 100%;
                        min-width: 250px;
                        padding-bottom: 10px;

                        @media (max-width: 1200px) {
                            h2 {
                                font-size: 16px;
                            }
                            span {
                                font-size: 14px;
                            }
        
                            p, a {
                                font-size: 14px;
                            }
                        }

                        @media (max-width: 769px) {
                            h2 {
                                font-size: 16px;
                                margin: 5px 10px 0px 10px;
                            }
                            span {
                                font-size: 14px;
                            }
        
                            p, a {
                                font-size: 13px;
                            }
                        }
                    }

                    &:hover {
                        box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, .2);
                        transform: scale(1.02);
                    }
                }
            }
        }
    }   
}



@import "../section/sectionOneNews.scss";
