.sectionActuGame {
    display: flex;
    flex-direction: column;
    padding: 20px 10%;

    .containerActuGame {
        display: flex;
        flex-direction: column;

        h2 {
            text-transform: uppercase;
            font-size: 70px;
            line-height: 58px;
            font-weight: 800;
            text-align: left;

            @media (max-width: 1200px) {
                font-size: 40px;
                line-height: 38px;
            }
            
            @media (max-width: 769px) {
                margin: 0;
                font-size: 38px;
                line-height: 48px;
            }
    
            @media (max-width: 521px) {
                font-size: 38px;
                line-height: 36px;
            }
    
            @media (max-width: 376px) {
                font-size: 30px;
                line-height: 28px;
            }
        }

        .autoplayActuGame {
            display: flex;
            align-items: center;
            font-weight: 200;
    
            .slick-list {
                flex:1;
            }
    
            i {
                font-size: 30px;
            }
    
            .slickPrev {
                margin-right: 10px;
                cursor: pointer;

            }
    
            .slickNext {
                margin-left: 10px;
                cursor: pointer;

            }
            
            .slickActuGame {
                margin: 20px;
                display: flex;
                flex-direction: column;
                outline: none!important;
                align-items: flex-start;

                & > div {
                    //width: 230px;
                    // max-width: 280px;

                    @media (max-width: 769px) {
                        h3 {
                            font-size: 16px;
                            margin: 10px 0 5px 0;
                        }
                        span {
                            font-size: 14px;
                        }
    
                        a {
                            font-size: 13px;
                        }
                    }
                }

                .imgSlickActuGame {
                    width: 230px;
                    height: 308px;
                    background-size: cover;
                    background-position: center;

                    @media (max-width: 520px) {
                        width: 200px;
                        height: 270px;
                    }
                    @media (max-width: 346px) {
                        width: 180px;
                        height: 270px;
                    }
                    //max-height: 120px;
                    
                    //width: 100%;
                }

                h3 {
                    margin-bottom: 0;
                    font-size: 21px;
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                span {
                    color: #909090;
                    font-weight: 200;
                    font-size: 16px;
                }
                
                article, p {
                    font-size: 13px;
                    margin: 10px;
                }

                a {
                    color: #000;
                    font-size: 12px;
                }
            }
        }

        .slickSoloResult {
            display: flex;
            margin: 20px 0 30px 0;
            //padding: 0 60px;

            @media (max-width: 769px) {
                flex-direction: column;
                align-items: center;
            }

            .imgSlickActuGame {
                margin-right: 60px;
                width: 230px;
                height: 310px;
                background-size: cover;
                background-position: center;

                @media (max-width: 769px) {
                    margin-right: 0;
                }
            }

            article {
                margin-bottom: 10px;
            }

            div{
                a {
                    color: #000;
                    text-decoration: underline;
                }
                span {
                    color: #909090;
                }
                article, p {
                    margin: 10px 0;
                }

                @media (max-width: 769px) {
                    h3 {
                        font-size: 16px;
                        margin: 10px 0 5px 0;
                    }
                    span {
                        font-size: 14px;
                    }

                    article, p, a {
                        font-size: 13px;
                    }
                }
               
            }
        }
            
        
    }

    .backgroundSlider {
        display: flex;
        width: 100%;
    }
}