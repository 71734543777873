.sectionTitleHome {
    width: 100%;
    //max-height: 500px;
    background-color:black;
    overflow: hidden;

    .containerTitleHome {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
        max-height: 500px;
        text-align: center;

        .text {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            width: 50%;
            padding-left: 12%;
            text-align: left;


            @media (max-width: 520px) {
                padding-right: 12%;
                width: inherit;
            }

            .titleInfo {
                font-weight: 700;
                font-size: 21px;
                color: #ff0000;
            }

            article {
                p {
                    font-weight: 200;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 70px;
                line-height: 58px;

                @media (max-width: 921px) {
                    font-size: 48px;
                    line-height: 41px;
                }

                @media (max-width: 650px) {
                    font-size: 38px;
                }
            }

            .btnSkew {
                width: 250px;
                cursor: pointer;
        
                div {
                    background-color: #000;
                    color: #fff;
                    max-height: 45px;
                    
                    span {
                        padding: 13px 0;
                        color: #fff;
                    }
                }
            }
        }

        img {
            height: auto;
            margin-bottom: -7px;
    
            @media (max-width: 1500px){
                width:100%;
                margin-bottom: -7px;
            }
    
            @media (max-width: 921px) {
                clip-path: none;
                position: unset;
                margin-bottom: -7px;
            }
        }

    }


    .containerTitleHomeVideo {
        color: #fff;
        position: relative;
        //max-height: 500px;
        text-align: left;
        display: flex;
        padding: 50px 10%;

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 50px 8%;
        }

        .text {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            .titleInfo {
                font-weight: 700;
                font-size: 21px;
                color: #ff0000;
            }

            article {
                p {
                    font-weight: 200;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 70px;
                line-height: 58px;
                margin-bottom: 10px;

                @media (max-width: 921px) {
                    font-size: 48px;
                    line-height: 41px;
                }

                @media (max-width: 650px) {
                    font-size: 38px;
                }
            }

            .btnSkew {
                width: 250px;
                cursor: pointer;
        
                div {
                    background-color: #000;
                    color: #fff;
                    max-height: 45px;
                    
                    span {
                        padding: 13px 0;
                        color: #fff;
                    }
                }

            }

        }

        img {
            display: flex;
            margin-left: 20px;
            width: 50%;

            @media (max-width: 768px) {
                margin: 40px 0 20px 0;
                width: 100%;
            }
        }

        .noText {
            flex: unset;
        }

        .noTextImg {
            width: 100%;
        }

        .containerImgVideo {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            max-height: 500px;

            .imgVideoInput {
                width: 70%;
                max-height: 400px;
            }
    
            .imgVideoInputIcon {
                position: absolute;
                width: 120px;
            }
        }

    }

    //Cas Pas de description
    .noDesc {
        .text {
            .btnSkew {
                margin-top:30px;
                cursor: pointer;
                @media (max-width: 650px){
                    margin-top:15px;  
                    width: 200px;
                    div {
                        span {
                            padding: 3px 0;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

}

.modalTitleHome {
    .modal {
        background: none;
        max-width: 90%;
        display: flex;
        flex-direction: row;

        .closePlayerVideoHome {
            color: white;
            font-size: 30px;
            margin-left: 10px;
        }
    }

    .jwplayer, .fp-hlsjs {
        margin: 0!important;
        width: 100%!important;
        max-height: 500px!important;
        height: 500px!important;
    }
}
