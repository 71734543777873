.btnSkew {
    display: block;
    width: calc(100% + 30px);
    padding: 3px;
    text-align: center;
    border: none;
    cursor: pointer;

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            font-size: 12px;
            font-family: Arial,Helvetica,sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            color: #000;
            white-space: nowrap;
            text-decoration: none;
            padding: 5px 0;
            margin:0 !important;
        }

        .plus {
            align-items: center;
            border: 1px solid #b9ba8e;
            border-radius: 100%;
            color: #b9ba8e;
            display: flex;
            font-size: medium;
            height: 30px;
            justify-content: center;
            width: 30px;
            margin-left:15px !important;
            padding:0px;
        }

    }
}
