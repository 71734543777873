.mentionLegalContainer {
    display: flex;
    flex-direction: column;
    flex: 1;

    h1 {
        margin: 30px 20%;
    }

    article {
        margin: 0px 20% 30px 20%;
    }
}
