
.nos-production {

    padding-top:100px;


    .categorie {

        ul {
            padding-left:0px;
            padding-right:0px;

            li {
                list-style-type: none;
                display: inline-block;
                width:19.5%;
                text-align:center;

                a {
                    font-size:21px;
                    font-family: 'Poppins', sans-serif;
                    font-weight:700;
                    color:black;

                    &:hover {
                        color:#ff0000;
                        text-decoration:none;
                    }
                }
            }
        }

    }

    .title {
        color: #000000;
        font-family: 'Poppins', sans-serif;
        font-size: 67px;
        font-weight: 900;
        line-height: 58px;
        margin-top:95px;
        margin-bottom:110px;
        text-align:center;
    }

    .production {

        margin-bottom:48px;

        img {
            padding: 15px;
            margin-bottom:0px;
        }

        .title {
            color: #000000;
            font-family: 'Poppins', sans-serif;
            font-size:25px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
            margin-top:36px;
            margin-bottom:5px;
        }

        .createdAt {
            font-size: 18px;
            color:#909090;
            font-family: 'Poppins', sans-serif;
            font-weight:500;
            text-align: center;
            line-height: 21px;
        }

    }
}