header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: white;
    width: 100%;
    z-index: 1;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .06);

    .logo {
        display: flex;
        align-items: center;
        margin: 0 10px 0 50px;

        i.fas {
            font-size: 18px;
            margin-left: 15px;
            cursor: pointer;
        } 
        a {
            height: 55px;

            img {
                max-height: 55px;
            }
        }
    }

    .logo, .nav {
        @media (max-width: 769px) {
            margin: 0 10px;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        margin: 0 50px 0 10px;

        @media (max-width: 376px) {
            margin: 0 10px;
        }

        a {
            display: flex;
            width: calc(100% + 30px);
            text-decoration: none;
            margin-right: 15px;

            @media (max-width: 421px) {
                margin-right: 10px;
            }

            div { 
                span {
                    margin: 0 20px;
                }
            }
        }

        i.fas {
            font-size: 20px;
            cursor: pointer;
        }

        i.fas, span {
            @media (max-width: 426px) {
                display: none;
            }
        }

        span {
            margin: 0 10px;
            font-size: 15px;
            text-transform: uppercase;
        }
        form {
            display: flex;
            justify-content: flex-end;
            position: absolute;

            @media (max-width: 520px) {
                position: relative;
            }

            .searchBar {
                background: #fff;
                z-index: 1000;
                font-size: 18px;
                padding: 0;
                border: none;
                border-bottom: 1.5px solid #000;
                transition: .3s;
                width: 0;
                padding: 0;
                height: 40px;
                position: relative;
                right: 10px;
            }
            
            .showSearchBar {
                padding-left: 5px;
                width: 100%;
            }
        }

        .icon-search {
            width: 50px;
            height: 22px;
            margin: 0 10px 0 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/loop.png");

            @media (max-width: 520px) {
                height: 23px;
                width: 25px;
            }
        }
        
        .icon-burger-close {
            width: 50px;
            height: 21px;
            margin: 0 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/images/burger-black.svg");
            cursor: pointer;

            @media (max-width: 520px) {
                height: 25px;
                width: 50px;
            }
            @media (max-width: 376px) {
                height: 25px;
                width: 80px;
            }
        }

        .btnConnexion {
            @media (max-width: 376px) {
                display: none;
            }
        }
    }

    .nav-right {
        display: flex;
        width: 620px;
        height: 100%;
        position: fixed;
        top: 0;
        right: -620px;
        overflow-x: hidden;
        transition: .4s;
        z-index: 99999;
        background-color: #000;


        @media (max-width: 769px) {
            width: 620px;
            right: -620px;
        }

        .icon-burger-open {
            display: flex;
            width: 31px;
            height: 31px;
            position: absolute;
            right: 87px;
            top: 30px;
            cursor: pointer;
            background-image: url("/images/svg/burger_menu_close.svg");
            color: #fff;
            font-size: 50px;

            @media (max-width: 769px) {
                right: 30px;
            }
        }

        ul {
            list-style-type: none;
            vertical-align: middle;
            display: inline-block;
            margin: auto 0;
            width: 100%;

            li {
                padding: 0 0 40px 60px;

                a {
                    padding: 0;
                    text-decoration: none;
                    font-size: 35px;
                    display: block;
                    transition: .3s;
                    text-transform: lowercase;
                    font-family: 'Poppins', sans-serif;
                    color: #818181;
                    font-weight: 600;
                    transition: .3s;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    .translationSelection {
        display: none;
        width: 100px;
        height: 80px;
        background: #fff;
        position: absolute;
        right: 10px;
        top: 60px;

        ul {
            margin: 0 auto;
            padding: 0;
            display: flex;
            align-self: center;
            flex-direction: column;
            list-style: none;
            text-transform: uppercase;
            font-size: 16px;

            li {
                align-self: center;

                a {
                    text-decoration: none;
                    color: #000;
                }
            } 
        }
    }

    .showTranslation {
        display: flex;
        z-index: 1;
    }

    .siteSelection {
        display: none;
        justify-content: center;
        background-color: white;
        position: absolute;
        top: 80px;
        width: 160px;
        left: 40px;
        overflow: hidden;
        overflow-y: auto;
        height: 60vh;

        ul {
            padding: 0;
            list-style: none;
            margin: 0;

            li {
                margin: 0;
                display: flex;
                height: 60px;
                align-items: center;
                text-align: center;

                img {
                    max-height:60px;
                }

                a {
                    color: #000;
                    width: 100%;
                    text-decoration: none;
                    font-size: 13px;

                    img {
                        max-height: 60px;
                    }

                }
            }
        }
    }

    .showSiteSelection {
        display: flex;
        z-index: 1;
    }

}
