.sectionContact {
    
    .containerContact {
        display: flex;
        flex-direction: column;
        background: #000;
        align-items: center;
        color: #fff;

        h2 {
            text-transform: uppercase;
            font-size: 70px;
            line-height: 58px;
            margin-bottom: 20px;
            margin-top: 60px;

            @media (max-width: 1200px) {
                font-size: 40px;
                line-height: 38px;
            }

            @media (max-width: 769px) {
                font-size: 35px;
                line-height: 38px;
            }
        }

        article {
            text-align: center;

            p {
                font-weight: 200;
                font-size: 14px;
            }
        }

        a {
            text-decoration: none;
            display: flex;
            justify-content: center;
            margin: 20px 0 58px 0;

            @media (max-width: 769px) {
               margin-bottom: 40px;
            }
            
            .btnSkew {
                //max-width: 300px;

                div {
                    background: #000;
                    
                    span {
                        color: #fff;
                        //padding: 15px 70px;
                        font-weight: 400;
                        font-size: 14px;

                        @media (max-width: 769px) {
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }
}