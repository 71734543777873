.sectionGridTv {
    
    .containerGridTvTitle {
        display: flex;
        flex-direction: column;
        padding: 5% 10% 0 10%;

        h2 {
            text-transform: uppercase;
            font-size: 70px;
            line-height: 58px;
            margin: 0;

            @media (max-width: 1200px) {
                font-size: 40px;
                line-height: 38px;
            }

            @media (max-width: 920px) {
                font-size: 35px;
                line-height: 38px;
            }
        }

        .menuGrid {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 20px 0 10px 0;
            border-bottom: 1px solid #cecece;
            padding-bottom: 10px;

            @media (max-width: 920px) {
                flex-direction: column;
            }

            p {
                margin: 0;
                text-align: center;
            }

            .toNight {
                font-size: 25px;
                text-transform: uppercase;
                font-weight: 700;
                color: #000;
                display: flex;
                flex: 1;
                text-align: left;
                margin: 0;
                padding-right: 20px;

                @media (max-width: 920px) {
                    font-size: 20px;
                }
            }

            .primeTimeMenu {
                flex: 1;
                display: flex;
                justify-content: space-between;
                width: 100%;

                .primetime1, .primetime2 {
                    color: #cecece;
                    font-size: 21px;
                    font-weight: 700;
                    cursor: pointer;
                    
                    &:hover {
                        background: #bbb094;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    
                    &.active {
                        background: #bbb094;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
    .containerGridTvSlider {
        display: flex;
        margin-left: 10%;
        max-width: 100%!important;

        .slider {
            display: flex;
            max-width: 100%;
            flex-direction: column;

            .autoplayGridTv{
                display: flex;
                align-items: center;
                font-weight: 200;
                width: 100%;
        
                i {
                    font-size: 30px;
                }
        
                .slickPrev {
                    margin-right: 10px;
                    cursor: pointer;
                }
        
                .slickNext {
                    margin-left: 10px;
                    cursor: pointer;
                }

                .slick-track {
                    display: flex;
                
                    .slickGridTv {
                        margin: 10px;
                        display: flex;
                        outline: none!important;
                        //max-width: 550px;

                        &:first-child {
                            margin-left: 0;
                        }

                        @media (max-width: 1070px) {
                            flex-direction: column;
                        }

                        @media (max-width: 520px) {
                            //max-width: 360px;
                        }


                        .imgSlickGridTv {
                            max-height: 255px;
                            width: auto;
                            margin-right: 20px;
                            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                            filter: grayscale(100%);
                            transition: .3s;

                            &:focus-visible {
                                -webkit-filter: inherit; /* Safari 6.0 - 9.0 */
                                filter: inherit;
                            }

                            @media (max-width: 1025px) {
                                //width: 245px;
                            }

                            @media (max-width: 520px) {
                                margin-right: 0;
                                max-height: 185px;
                                //width: 300px;
                            }

                            @media (max-width: 426px) {
                                max-height: 160px;
                            }
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            h3 {
                                margin: 0;
                                font-size: 24px;
                            }
        
                            span {
                                color: #909090;
                                font-weight: 200;
                                font-size: 18px;

                                @media (max-width: 920px) {
                                    margin-top: 10px;
                                }
                            }
                            
                            p {
                                font-size: 14px;
                                //max-width: 300px;
                                height: 150px;
                                overflow: hidden;
                                overflow-y: auto;
                                scrollbar-width: none; //firefox

                                &::-webkit-scrollbar { //other
                                    width: 0; 
                                    height: 0; /* Remove scrollbar space */
                                    background: transparent;
                                }

                                &:last-child {
                                    margin-right: 40px;
                                }
                            }
                        }

                        &.slick-current .imgSlickGridTv {
                            -webkit-filter: inherit; /* Safari 6.0 - 9.0 */
                            filter: inherit;
                        }

                    }
                }
            }
        }
    }

    .containerOneDay {
        display: flex;
        background: #000;
        color: #fff;
        flex-direction: column;
        margin-top: 40px;
        margin: 10px 10%;
        padding: 20px 40px 40px 40px;

        @media (max-width: 520px) {
            margin: 0;
            padding: 5px;
        }

        .menuOneDay {
            display: flex;
            justify-content: space-between;
            color: #fff;
            border-bottom: 1px solid #4d4d4d;
            margin-bottom: 20px;

            p {
                margin: 0;
                white-space: nowrap;
            }

            .todayIndicator {
                align-self: center;
            }

            @media (max-width: 520px) {
                flex-direction: row;
                align-items: center;
                text-align: center;
            }

            .calendarInput {
                display: flex;
                cursor: pointer;
                margin-left: 10px;
                margin-bottom: 10px;
                margin: 10px;
                flex: 1;

                .fa-calendar-alt {
                    margin-right: 10px;
                    font-size: 30px;
                    background: #bbb094;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .containerFiltreOneDay {
                display: flex;
                overflow: hidden;
                //overflow-x: scroll;
                white-space: nowrap;
                justify-content: space-around;
                flex: 3;
                height: 50px;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    width: 0; 
                    height: 0; /* Remove scrollbar space */
                    background: transparent;
                }

                @media (max-width: 895px) {
                    justify-content: space-between;
                    overflow-x: auto;
                }

                .filtreOneDay {
                    cursor: pointer;
                    color: #4d4d4d;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                    padding: 10px;
                    text-align: center;

                    @media (max-width: 520px) {
                        font-size: 14px;
                    }

                    &:first-child {
                        padding-left: 10px;
                    }

                    &:last-child {
                        padding-right: 30px;
                    }
                }

                .filtreOneDay.active {
                    color: white;
                }

            }
        }

        .containerPoster {
            display: flex;  
            display:  -webkit-flex;
            flex-wrap: wrap;            
            -webkit-flex-wrap: wrap;
            background: #000;
            color: #fff;

            &:after {
                content: "";
                flex: auto;
                margin-left: 31.9%;
            }

            .descriptionJaquette {
                display:none;
                width: 100%;
                padding: 24px 36px;                
                min-height: 80px;
                background-color: #ffffff;
                position: absolute;
                top: calc(100% + 40px);
                left: 0px;
                right: 0px;
                margin-left: 0px;
                z-index: 0;

                div {
                    position: relative;
                    width: 100%;
                    
                    img#close {
                        position: absolute;
                        top: -5px;
                        right: -15px;
                        width: 20Px;
                        height: 20px;
                        padding: 0px;
                        cursor: pointer;
                    }
                    p {
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 0;
                        text-align: left;
                    }
                    p#titleJaquette {
                        font-size: 25px;
                        font-weight: 700;
                        margin: 0;
                    }
                }
            }
            .one.active {
                &:after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    transform: rotate(45deg);
                    bottom: -50px;
                    left: 45px;
                    background: white;
                }
            }
            .one {
                display: none;
                flex: 1 0 30%;
                margin: 10px;

                .divImgJaquette {
                    margin-left: 0;
                    width: 102px;
                    height: 145px;
                    flex: initial;
                    //border: 1px solid #000;
                    transition: .2s;
                    border: 2px solid transparent;
                    border-image-source: #000;
                    border-image-slice: 1;

                    &:hover {
                        border-image-source: linear-gradient(-45deg, #ff8080 0%, #996ad4 100%);
                    }

                }
                .containerInput {
                    display: flex;
                    flex: 1;
                    flex-direction: column; 
                    justify-content: center;     
                    margin-left: 36px;

                    p {
                        margin: 0;
                    }
                
                    .horraireJaquette {
                        width: 168px;
                        height: 36px;
                        color: #909090;
                        font-size: 18px;
                        font-weight: 400;
                        text-align: left;
                    }
    
                    .titleJaquette {
                        color: #fff;
                        font-size: 21px;
                        font-weight: 700;
                        text-align: left;
                        line-height: 27px;
                    }

                }
                .imgJaquette {
                    width: 102px;
                    height: 145px;
                    cursor: pointer;
                }
            }

            .showProg {
                display: flex;
                position: relative;
                max-height: 145px;
            }
        }

        //calendar
        .wrapperCalendar {
            display: none;

            .containerCalendar {
                width: 100%;
                padding: 15px 0;
                margin: 0 auto;
                overflow: auto;

                .buttonContainerCalendar {
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    div {
                        display: flex;

                        button {
                            background: none;
                            border: none;
                            color: #fff;
                            padding: 5px 0;
                            margin-right: 10px;
                        }

                        .dateChoose {
                            display: flex;

                            #monthHeader, #yearHeader {
                                color: #fff;
                                margin: 0;
                                font-size: 18px;
                            }

                            #monthHeader {
                                margin-right: 5px;
                            }
                        }
    
                        #previous {
                            font-size: 15px;
                            float: left;
                            margin: 0 10px 0 0;
                        }
                        
                        #next {
                            font-size: 15px;
                            float: right;
                            margin: 0 10px;
                        }

                        .hideInputMonth {
                            display: none;
                        }
                    }

                } 

                .tableCalendar {
                    border-collapse: collapse;
                    width: 100%;
                    padding: 0;

                    tr {
                        border-bottom: 1px solid #4d4d4d;
                    }

                    td, th {
                        padding: 10px;
                        text-align: center;
                        font-weight: 300!important;
                        height: 40px;
                    }

                    th {
                        @media (max-width: 768px) {
                            padding: 0;
                        }
                    }

                    td {
                        height: 90px;

                        @media (max-width: 768px) {
                            height: 45px;
                        }

                        span {
                            pointer-events: none;
                        }
                    }
                }

                .date-picker {
                    cursor: pointer;
                }
            }
        }
        
        .date-picker{
            pointer-events: none;

            &.selected {
                font-weight: bold;
                //outline: 1px solid #00BCD4;
                background-image: linear-gradient(-53deg, #ff0000 0%, #5e00d4 100%);
                
                span {
                    color: #fff;
                }
            }
        }

        .past, td {
            color: #4d4d4d;
        }

        .past {
            pointer-events: none;
        }
        
        .future, .today{
            color: #fff;
            pointer-events: unset;
        }
        

        .footerContainerCalendar {
            margin-top: 1em;
            border-top: 1px solid #dadada;
            padding: 10px 0;
            display: none;

            select {
                cursor: pointer;
                display: inline-block;
                zoom: 1;
                background: #ffffff;
                color: #585858;
                border: 1px solid #bfc5c5;
                border-radius: 3px;
                padding: 5px 1em;
            }
        }

        #monthAndYear {
            text-align: center;
            margin-top: 0;
        }
        //end calendar

    }

}
