.sectionSliderHome {

    .containerSliderHome {
        display: flex;
        justify-content: center;
        align-items: center;
        //width: 100%!important;

        .slider {
            display: flex;
            position: absolute;
            max-width: 100%;
            flex-direction: column;
            width: 100%;

            .autoplayHome{
                display: flex;
                margin: 0 240px;
                background: black;
                align-items: center;
                color: white;
                padding: 15px 40px;
                font-weight: 200;
                max-height: 370px;
                
                @media (max-width: 921px) {
                    margin: 0 10%;
                }
        
                @media (max-width: 769px) {
                    margin: 40px 30px;
                    padding: 10px;
                }
        
                @media (max-width: 426px) {
                    margin: 40px 10px;
                }
        
                .slick-list {
                    width: 100%;
                    flex:1;
                }
        
                i {
                    font-size: 30px;
                }

                .containerVid {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .couvVideo {
                        height: 310px;
                        width: auto;
                        max-width: 99%;
                        display: flex;
                        flex: 1;

                        @media (max-width: 1024px) {
                            width: 100%;
                            height: 200px;
                        }

                        @media (max-width: 520px) {
                            height: 150px;
                        }
                
                    }
                    
                    .playIcon {
                        width: 80px;
                        height: 80px;
                        display: flex;
                        position: absolute;
                        flex: 1;
                        //top: 36%;
                    }
                }
        
                .slickPrev {
                    margin-right: 10px;
                    cursor: pointer;
                }
        
                .slickNext {
                    margin-left: 10px;
                    cursor: pointer;
                }
                
                .slickSliderHome {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    outline: none!important;
                    // width: 100%;

                    iframe {
                        display: flex;
                        margin: 50px 0;
                        position: relative;
                        top: 0px;
                        max-width: 600px;
                    }
                    
                    p {
                        font-size: 14px;
                        margin: 0;
                        margin-bottom: 10px;
                        text-align: center;

                        @media (max-width: 921px) {
                            font-size: 10px;
                        }
                    }

                    a {
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 10px;
                        
                        .btnSkew {
                            max-width: 300px;

                            div {
                                background: #000;
                                
                                span {
                                    color: #fff;
                                    padding: 15px 70px;
                                    font-weight: 400;
                                    font-size: 14px;

                                    @media (max-width: 769px) {
                                        padding: 15px 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .backgroundSlider {
        display: flex;
        width: 100%;
        min-height: 460px;
        max-height: 460px;

        @media (max-width: 1024px) {
            min-height: 290px;
            max-height: 290px;
        }

        @media (max-width: 520px) {
            min-height: 200px;
            max-height: 200px;
        }
    }
}

.modalPlayerVideo {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 1;
    justify-content: center;
    align-items: center;
    top: 0;
    transition: .3s;

    > div {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        padding: 10px;

        i {
            color: #fff;
            margin-left: 10px;
            font-size: 30px;
        }

        .fp-hlsjs, .jwplayer {
            margin: 0!important;
            width: 80%!important;
            max-height: 500px!important;
        }
    }

}
