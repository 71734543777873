.sectionLiveReplay {
    display: flex;
    flex-direction: column;
    background: #000;
    align-items: center;
    
    .containerLiveReplay {
        color: #fff;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            text-transform: uppercase;
            font-size: 70px;
            line-height: 58px;

            @media (max-width: 1200px) {
                font-size: 35px;
                line-height: 38px;
            }

            @media (max-width: 769px) {
                font-size: 26px;
                line-height: 38px;
            }
        }

        img {
            max-width: 80%;
            display: flex;
            margin: 0 auto;

            @media (max-width: 769px) {
                max-width: 420px;
            }

            @media (max-width: 426px) {
                max-width: 300px;
            }
        }
    }

}