footer {
    color: #000;
    padding: 50px 10%;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, .06);
    
    .containerFooter {
        display: flex;
        justify-content: space-between;
        min-height: 150px;
        margin-bottom: 30px;

        @media (max-width: 979px) {
            flex-direction: column;
        }

        .contactInfo {
            margin-left: 80px;

            @media (max-width: 1025px) {
                margin-left: 60px;
            }

            @media (max-width: 979px) {
                margin-left: 30px;
                order: 5;
            }

            p {
                font-size: 14px;
            }
        }

        .navFooter, .navJuridic, .navFooterOther {
            flex: 1;
            margin: 0 30px;

            @media (max-width: 979px) {
                order: 5;
            }

            h3 {
                margin: 0 0 15px 0;
                font-size: 21px;
                font-weight: 600;

                @media (max-width: 979px) {
                    margin: 15px 0 0 0;
                }
            }

            ul {
                list-style: none;
                margin: unset;
                padding: 0;

                li {
                    margin-bottom: -3px;

                    a {
                    font-size: 14px;
                    color: #000;
                    font-weight: 300;
                    text-decoration: none;

                        &::first-letter {
                            text-transform: uppercase;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .navJuridic {
            flex: 0.4;
        }

        .newsletterContainer {
            display: flex;
            flex-direction: column;
            //margin-right: 10%;

            @media (max-width: 979px) {
                display: flex;
                padding: 30px;
                margin-right: 0;
                order: 0;
            }

            .btn-footer {
                @media (max-width: 979px) {
                    width: 95%;
                }
            }

            .rsList {
                font-size: 20px;
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                
                a {
                    margin-right: 10px;

                    i {
                        color: #000;
                    }
                }
            }
            a {
                text-decoration: none;

                .btnConnexionFooter {
                    display: none;
                    width: 95%;
                    margin-bottom: 20px;
    
                    @media (max-width: 376px) {
                        display: block;
                    }
                }
            }
        }
    }

    #footerLogo {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 425px) {
            margin-top: 20px;
        }
    }

}

.twitter-x {
    background-image: url('/images/twitter-x.webp');
    background-size: cover;
    width: 20px;
    height: 20px;
    margin-top:5px;
}

.tiktok-logo {
    background-image: url('/images/tiktok.png');
    background-size: cover;
    width: 20px;
    height: 20px;
    margin-top:5px;
}