.sectionContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerContactForm {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        //margin: 5% 10%;

        @media (max-width: 769px) {
            flex-direction: column;
        }

        .containerText {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1.1;
            height: 630px;
            background: #000;
            color: #fff;
            padding: 20px 60px;
            clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);

            @media (max-width: 769px) {
                clip-path: none;
            }

            h1 {
                text-transform: uppercase;
                font-size: 70px;
                line-height: 72px;
                font-weight: 800;
                display: flex;

                @media (max-width: 920px) {
                    font-size: 61px;
                    line-height: 54px;
                }
    
                @media (max-width: 520px) {
                    font-size: 52px;
                    line-height: 54px;
                }
            }

            p {
                font-size: 21px;
                text-align: left;
                max-width: 80%;
            }

            p:last-child {
                margin-bottom: 0;
            }

            p.boldInfo {
                font-weight: 700;
                font-size: 21px;
                text-align: left;
                max-width: 80%;
            }

        }

        .containerForm {
            display: flex;
            flex-direction: column;
            flex: 1;

            form {
                display: block;
                margin: 20px 40px 20px 0 ;

                @media (max-width: 769px) {
                    margin: 20px 25px 20px 15px;
                }

                label {
                    display: flex;
                    width: 35%;
                    float: left;
                    justify-content: flex-end;
                    margin-bottom: 20.5px;
                    height: 40px;
                    align-items: center;
                    text-align: right;

                    @media (max-width: 769px) {
                        display: none;
                    }
                }

                input, textarea {
                    padding: 0;
                    display: flex;
                    width: 61%;
                    float: right;
                    resize: vertical;

                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #cecece;
                        opacity: 1; /* Firefox */
                    }
                    
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #cecece;
                    }
                    
                    &::-ms-input-placeholder { /* Microsoft Edge */
                        color: #cecece;
                    }

                    @media (max-width: 769px) {
                        width: 95%;
                        margin: 20px 0;
                    }
                }

                input {
                    border: none;
                    border-bottom: 1px solid #cecece;
                    margin-bottom: 20px;
                    height: 40px;
                }

                textarea {
                    border: 1px solid #cecece;
                    margin-bottom: 20px;
                    padding: 5px;
                    width: 59%;
                    max-height: 150px;
                    min-height: 60px;

                    @media (max-width: 769px) {
                        width: 95%;
                        margin: 20px 0;
                    }
                }

                .captchaContainer {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    div {
                        display: flex;
                        flex-direction: column;
                        label {
                            margin-top: 33px;
                            display: none;
                        }

                        .BDC_CaptchaDiv, input {
                            display: flex;
                            flex-direction: row;
                            align-self: flex-end;
                        }
                        input {
                            width: 61%;
                            @media (max-width: 769px) {
                                width: 96.5%;
                            }
                        }
                        .BDC_CaptchaDiv {
                            @media (max-width: 769px) {
                                align-self: center;
                            }
                        }
                    }
                }
            }


            .btnSkew {
                display: flex;
                width: 45%;
                margin: 20px 20px 20px 39%;

                @media (max-width: 769px) {
                    width: 60%;
                    margin: 20px auto;
                }

                div {
                    flex: 1;
                    height: 40px;
                }
            }

            .btnSend {
                visibility: hidden;
                height: 0;
            }

            .rgpdAdvertisement {
                font-size: 11px;
                font-style: italic;
                margin-left: 20%;

                @media (max-width: 769px) {
                    margin: 0 5%;
                }

                a {
                    text-decoration: underline;
                    color: initial
                }
            }

            .alert-success {
                color: #90C97E;
                transition: .3s;
            }
        }
    }
}
